<template>
  <div id="manager-card" class="manager-card">
    <div v-if="!isMobile" class="manager-card__title">Your manager:</div>
    <div class="manager-card__content">
      <UIAvatar
        width="96"
        height="96"
        class="manager-card__avatar"
        :user="manager"
        :src="manager.image"
        no-border
        alt="team-member-1"
      />
      <p v-if="!isMobile && manager?.name" class="manager-card__name">{{ manager.name }}</p>
      <a v-if="manager.phone_country" :href="callUrl" class="manager-card__phone">
        <UIIcon v-if="isMobile" size="xs" name="whatsapp-fill" />
        <UIIcon v-else size="xs" name="phone" />
        <span>+{{ manager.phone_country.phone_code }} </span>{{ manager.phone }}
      </a>
      <a v-if="manager?.email" :href="`mailto:${manager.email}`" class="manager-card__email">
        <UIIcon size="xs" name="email" />
        <span>{{ manager.email }}</span> </a
      ><a
        v-if="manager.phone_country"
        :href="`tel:+${manager.phone_country.phone_code}${manager.phone}`"
        class="manager-card__call"
      >
        <UIButton v-if="isMobile" id="call-manager" size="small" variant="secondary"> Call manager </UIButton>
      </a>
      <FeaturesGradient v-if="!isMobile" left="-52" bottom="-51" color="gold-primary-100" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { User } from '~/types/auth'

const { isMobile } = useAppBreakpoints()

const props = defineProps<{
  manager: User
}>()

const callUrl = computed(() => {
  const phone = `+${props.manager.phone_country.phone_code}${props.manager.phone}`
  if (isMobile) return `https://api.whatsapp.com/send?phone=${phone}&text=Hey`

  return `tel:${phone}`
})
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
.manager-card {
  &__title {
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 8px;
    text-align: center;
  }

  &__content {
    border-radius: 24px;
    border: 1px solid $black-60;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 3px 44.9px 0px rgba(0, 0, 0, 0.12);
    padding: 12px 24px;
    width: 100%;
    min-height: 202px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 1023px) {
      min-height: auto;
      background-color: white;
      box-shadow: none;
      border-width: 0.5px;
      display: grid;
      grid-template-rows: repeat(2, minmax(0, 1fr));
      grid-template-columns: 70px 1fr;
      padding: 12px;
      gap: 8px;
    }
  }

  &__avatar {
    margin-bottom: 16px;

    @media screen and (max-width: 1023px) {
      margin-bottom: 0;
      width: 70px !important;
      height: 70px !important;
      grid-row: span 2 / span 2;
    }
  }

  &__name {
    @include subhead3();
    margin-bottom: 12px;
  }

  &__phone,
  &__email {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    color: $black-100;
    @include caption2();
    width: 100%;
    @media screen and (max-width: 1023px) {
      grid-column: span 2 / span 2;
      margin-bottom: 0;
      background-color: $bg;
      padding: 5px 8px;
      border-radius: 8px;
      .icon {
        margin-left: 0;
      }
    }

    span {
      margin-left: 4px;
    }
  }

  .tooltip {
    width: 100%;
  }

  &__email {
    margin-bottom: 0;
    width: 100%;
    overflow: hidden;

    .icon {
      flex-shrink: 0;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__call {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 4;
    .button {
      width: 100%;
      border-radius: 8px;
      font-size: 14px;
    }
  }
}
</style>
